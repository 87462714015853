// @flow
import React from 'react';
import { graphql } from 'gatsby';
import {
  Carousel,
  GlossaryLink as G,
  Image,
  Layout,
  withFrontMatter,
} from '../components';
import type { FrontMatter, ImageType } from '../utils/types';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _6_in_diameter: ImageType,
    tree_poorly_rooted: ImageType,
    tree_ring_bend: ImageType,
    tree_frost: ImageType,
    tree_basket: ImageType,
    tree_wrap_3_pull_2: ImageType,
    tree_girth_hitch: ImageType,
    rock: ImageType,
    rock_frost: ImageType,
  },
};

class Canyoneering_SingleSolidNaturalAnchors extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <Image image={data.tree_basket} />
        <p>
          Welcome to the exciting world of big trees and rocks! Let's talk about
          what makes them single <G>solid</G> <G>natural-anchors</G>.
        </p>
        <h2>Only single?</h2>
        <p>
          Note the title of this article has the word "single" in it. Does that
          mean the anchor material is not <G>redundant</G>? Yes it does. The
          idea of a single solid anchor means the anchor is so big that if it
          were to fail there are probably bigger problems that caused it to fail
          that are going to kill you.
        </p>
        <p>
          Let's say you are tied into a huge boulder and it suddenly starts
          tumbling towards you. What caused that boulder to start tumbling
          towards you? A land slide is the main thing that come to mind. If you
          don't die from the fall you'll be crushed or suffocate.
        </p>
        <h2>Solid anchors</h2>
        <p>
          There are two types of single solid natural anchors:{' '}
          <G id="big-friendly-rock">big fu-riendly rocks</G> and big friendly
          trees.
        </p>
        <h2>Trees</h2>
        <p>
          A tree is a solid anchor when it has the following three properties:
          six inches in diameter, alive, and well rooted.
        </p>
        <h3>1) Six inches in diameter</h3>
        <Image
          image={data._6_in_diameter}
          caption="The red line is the diameter of that tree. It should be at least 6 inches"
        />
        <p>
          The diameter of a tree is easy to calculate. Just cut down the tree
          and measure! If it's 6 inches or greater, then it would have worked as
          an anchor!
          <br />
          That was a joke. Please don't <em>actually</em> do that. Geez don't
          take everything so literally.
        </p>
        <p>
          Why 6 inches? I don't know why that exact length was picked. Probably
          because it is really strong.
        </p>
        <h3>2) Alive</h3>
        <p>
          If it has green leaves on it then it's probably alive. If it's just a
          piece of a tree floating in a pothole then it's probably dead. If it's
          anything in between you'll have to use your own judgement.
        </p>
        <h3>3) Well rooted</h3>
        <Image
          image={data.tree_poorly_rooted}
          caption="Long root going down a crack. Is this well rooted? Would you trust it?"
        />
        <p>
          This means the roots are in the ground. Impossible to actually see but
          there have only been a few times where I wasn't absolutely sure.
        </p>
        <hr />
        <p>
          If it has all three of these qualities then you can trust it to hold
          you on rappel. Let's quickly discuss some <em>almost</em> optimal
          trees.
        </p>
        <p>
          What if you have a tree that is 5.9 inches in diameter, alive, and
          well rooted? Or a dead tree that's 36 inches in diameter and very well
          routed? Is it still safe to rappel on either of those? I don't know.
          Um do what your heart tells you. Don't trust everything you read on
          the internet.
        </p>
        <h3>Webbing around trees</h3>
        <Carousel
          images={[data.tree_ring_bend, data.tree_frost, data.tree_basket]}
          captions={[
            'Single loop of webbing with a ring bend. This is the most common rigging from a tree',
            <>
              Single loop of webbing with a <G>frost knot</G>
            </>,
            <G>Redundant wrap</G>,
          ]}
        />
        <p>
          Most commonly you will see a single loop of <G>webbing</G> tied with a{' '}
          <G>ring bend</G> but occasionally you will see other things.
        </p>
        <p>
          When tying webbing around trees the strongest place for the webbing to
          go is at the base of the tree. The base of the tree is also the place
          where it makes the rappel starts hardest for people and it can make
          the <G>pull</G> more difficult.
        </p>
        <Carousel
          images={[data.tree_wrap_3_pull_2, data.tree_girth_hitch]}
          captions={[
            <>
              <G>Wrap 3 pull 2</G> - a type of cinch wrap
            </>,
            <>
              <G>Girth hitch</G> - another cinch wrap
            </>,
          ]}
        />
        <p>
          If the tree is strong enough you can use a <G>cinch wrap</G> to put
          the webbing higher up in the tree to eliminate the tough start and
          tough pull. If the tree is not strong enough you can use{' '}
          <G>courtesy rigging</G> to make the rappel start a little easier while
          making the pull easy as well.
        </p>
        <h2>Rocks</h2>
        <Image image={data.rock} caption="A big rock" />
        <p>
          Rocks don't have exact requirements like trees, unfortunately. I'll go
          over a few things to think about and call it good.
        </p>
        <ul>
          <li>
            If you can't move the rock at all in the direction of rappel that's
            a good thing
          </li>
          <li>
            If the rock Is on a slope that goes down into the canyon that's bad.
            Move it behind a lip or at lease on a more level surface.
          </li>
          <li>
            If the rock is sitting on sand or gravel that's bad. Move the rock
            or move the sand/gravel out from under it.
          </li>
          <li>
            Make sure the webbing doesn't go around any sharp corners on the
            rock. If that's not possible to avoid then double up the webbing in
            those places for additional protection.
          </li>
          <Image
            image={data.rock_frost}
            caption="A rock with webbing and a frost knot"
          />
        </ul>
      </Layout>
    );
  }
}

export default withFrontMatter('single-solid-natural-anchors')(
  Canyoneering_SingleSolidNaturalAnchors
);

export const query = graphql`
  query {
    _6_in_diameter: file(
      relativePath: {
        eq: "images/canyoneering/single-solid-natural-anchors/_6_in_diameter.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tree_poorly_rooted: file(
      relativePath: { eq: "images/canyoneering/anchors/_01.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tree_ring_bend: file(
      relativePath: { eq: "images/canyoneering/anchors/_02.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tree_frost: file(
      relativePath: { eq: "images/canyoneering/anchors/_04.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tree_basket: file(
      relativePath: { eq: "images/canyoneering/anchors/_07.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tree_wrap_3_pull_2: file(
      relativePath: { eq: "images/canyoneering/anchors/_05.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    tree_girth_hitch: file(
      relativePath: { eq: "images/canyoneering/anchors/_09.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rock: file(relativePath: { eq: "images/canyoneering/anchors/_13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    rock_frost: file(
      relativePath: { eq: "images/canyoneering/anchors/_12.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
